import * as React from "react"
import BaseLayout from "../components/layout"
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql, useStaticQuery } from "gatsby";
import HorizontalRule from "../components/horizontal-rule";
import TextBlock from "../components/text-block";
import Logo from "../components/logo";
import SocialIcon from "../components/social-icon";
import ArticleImage from "../components/article-image";
import BreadcrumbTrail from "../components/breadcrumb-trail";


const AboutPage = (props) => {
  const { site } = useStaticQuery(query);

  const aboutText = site.siteMetadata.aboutText
  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'About',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="About" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-px-base pb-28">
        <div className="">
          <StaticImage className="w-full block app-image app-full-width-child" src="https://media.zackthoutt.com/img/zack-tmb.jpeg" alt="Zack hiking Tour du Mont Blanc" />

          <div className="mt-60 mb-16 md:mb-32 text-mint">
            <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h2 className="text-subheader-1 mb-4">Profile</h2>
            <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">Zack Thoutt</h1>
          </div>

          <div className="my-16 layout-base-w flex flex-col md:flex-row">
            <div className="mx-auto md:mr-8 md:ml-0 mb-12 md:mb-1 block app-image max-w-full sm:[max-width:300px] md:[margin-top:11px]">
              <ArticleImage src="https://media.zackthoutt.com/img/zack-profile-small.jpeg" alt="Zack profile picture"/>
            </div>
            <div style={{'maxWidth': '620px'}}>
              <TextBlock text={aboutText} textSize="large"></TextBlock>
            </div>
          </div>

          <div className="text-mint my-60">
            <HorizontalRule ruleType="4"></HorizontalRule>
          </div>

          <div className="mt-60 mb-16 md:mb-32 text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 style={{'maxWidth': '620px'}} className="text-header-1 w-full">30-Second Resume</h1>
          </div>

          <div className="my-20 layout-reading-w md:flex justify-between items-start">
            <div className="flex-2 md:mr-12 mb-12 md:mb-0">
              <h2 className="mb-4 text-subheader-1">Currently</h2>
              <h3 className="my-8 text-header-3">Building vSaaS <a href="https://autosalesvelocity.com" className="text-link">@autosalesvelocity</a><sup>'16-present</sup></h3>
              <h3 className="my-8 text-header-3">Beating Vegas <a href="https://gridironai.com" className="text-link">@gridironai</a><sup>'17-present</sup></h3>
              <h3 className="mb-8 text-header-3">Writing fiction <Link to="/writing/library/" className="text-link">@here</Link><sup>'21-present</sup></h3>
            </div>
            <div className="flex flex-col items-start my-20 md:mb-0 md:mt-4 text-mint">
              <h4 className="mb-4 text-subheader-1 md:hidden">Contact</h4>
              <div className="mb-8">
                <SocialIcon icon="newsletter" siteData={site.siteMetadata}>
                  <h5 className="text-header-3 ml-2 text-link">Newsletter</h5>
                </SocialIcon>
              </div>
              <div className="mb-8">
                <SocialIcon icon="email" siteData={site.siteMetadata}>
                  <h5 className="text-header-3 ml-2 text-link">Email</h5>
                </SocialIcon>
              </div>
              <div className="mb-8">
                <Link to={'/contact'}>
                  <div className="relative group cursor-pointer">
                    <div className="w-full flex justify-center items-center text-mint hover:text-lightblue active:text-black">
                      <div className="">
                        <svg width="24" height="24" viewBox="0 0 112 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_2211_303)">
                            <path
                              d="M61.6 22.5C61.6 34.9277 51.5725 45 39.2 45C26.8275 45 16.8 34.9277 16.8 22.5C16.8 10.0723 26.8275 0 39.2 0C51.5725 0 61.6 10.0723 61.6 22.5ZM67.2 53.4375L78.4 90H0L11.2 53.4375H67.2ZM112 90H84.2625L83.755 88.3477L73.92 56.25H100.8L112 90ZM75.6 45C70.175 45 65.275 42.7852 61.7225 39.2168C65.17 34.541 67.2 28.7578 67.2 22.5C67.2 17.7891 66.045 13.3418 63.9975 9.43945C67.2525 7.04883 71.26 5.625 75.6 5.625C86.4325 5.625 95.2 14.4316 95.2 25.3125C95.2 36.1934 86.4325 45 75.6 45Z"
                              fill="currentColor"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_2211_303">
                              <rect width="112" height="90" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <h5 className="text-header-3 ml-2 text-link">Follow</h5>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="mb-12 mt-10 layout-reading-w">
            <h2 className="mb-4 text-subheader-1">Previously</h2>
            <h3 className="my-8 text-header-3">Digital nomaded <Link to="/writing/library/nomad-life" className="text-link">@earth</Link><sup>'21-'22</sup></h3>
            <h3 className="mb-8 text-header-3">Created the <a href="https://www.vice.com/en/article/evvq3n/game-of-thrones-winds-of-winter-neural-network" className="text-link" target="_blank" rel="noreferrer noopener">Game of Thrones AI</a> that went viral<sup>'17</sup></h3>
            <h3 className="my-8 text-header-3">Modeled data @mocavo, acquired by DC Thompson<sup>'14-'16</sup></h3>
            <h3 className="my-8 text-header-3">Graduated Summa Cum Laude from the University of Colorado<sup>'11-'15</sup></h3>
          </div>

          <div className="text-mint my-60">
            <HorizontalRule ruleType="3"></HorizontalRule>
          </div>

          <div className="layout-reading-w text-center mt-20">
            <h1 className="text-header-1 w-full text-center text-mint">Quick Facts</h1>
            <div className="my-12 flex justify-center items-center text-mint">
              <HorizontalRule ruleType="7"></HorizontalRule>
            </div>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">My background...</h2>

            <h3 className="text-header-3 mb-8">Born in Denver, CO</h3>
            <h3 className="text-header-3 mb-8">Grew up in Broomfield, CO</h3>
            <h3 className="text-header-3 mb-8">Live in Boulder, CO</h3>
            <h3 className="text-header-3 mb-8">Married to <a href={site.siteMetadata.shelbyUrl} target="_blank" rel="noopener noreferrer" className="text-link">@shelbythoutt</a></h3>
            <h3 className="text-header-3 mb-8">Father to one son, Rory.</h3>
            <h3 className="text-header-3 mb-8">Dog-father to <a href={site.siteMetadata.skutullUrl} target="_blank" rel="noopener noreferrer" className="text-link">@skutull</a></h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">Random...</h2>

            <h3 className="text-header-3 mb-8">I'm often hiking, whether I'm exploring the Indian Peaks in my Boulder backyard or enjoying a multi-day hike in some far-flung corner of the world</h3>
            <h3 className="text-header-3 mb-8">I taught my dog, Skutull, how to bring me beers and recycle the empties</h3>
            <h3 className="text-header-3 mb-8">I typically meditate every day</h3>
            <h3 className="text-header-3 mb-8">I'm a Colorado Hobbit (all 8 of my great grandparents lived and died in Colorado)</h3>
            <h3 className="text-header-3 mb-8">I consider myself a skilled home chef and enjoy hosting small dinner parties</h3>
            <h3 className="text-header-3 mb-8">I dream of someday owning a land in the mountains where I can fly fish for trout, forage for chanterelles, hunt for elk, and escape the busyness of modern life.</h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">My favorite stories are...</h2>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Fiction Books</h4>
            <h3 className="text-header-3 mb-8">"The Kingkiller Chronicles" by Patrick Rothfuss</h3>
            <h3 className="text-header-3 mb-8">"A Song of Ice and Fire" by George R.R. Martin</h3>
            <h3 className="text-header-3 mb-8">"Harry Potter" by J.K. Rowling</h3>
            <h3 className="text-header-3 mb-8">"Where the Red Fern Grows" by Wilson Rawls</h3>
            <h3 className="text-header-3 mb-8">"The Old Man and The Sea" by Ernest Hemingway</h3>
            <h3 className="text-header-3 mb-8">"The Alchemist" by Paulo Coelho</h3>
            <h3 className="text-header-3 mb-8">"The Merchant and the Alchemist's Gate" and "Story of Your Life" by Ted Chiang</h3>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Nonfiction Books</h4>
            <h3 className="text-header-3 mb-8">"The Essays of Warren Buffett" by Warren Buffett</h3>
            <h3 className="text-header-3 mb-8">"The Spirit of St. Andrews" by Alister Mackenzie</h3>
            <h3 className="text-header-3 mb-8">Any book by Peter Zeihan</h3>
            <h3 className="text-header-3 mb-8">"The Outsiders" by William N. Throndike Jr.</h3>
            <h3 className="text-header-3 mb-8">"A Man for All Markets" by Edward O. Thorp</h3>
            <h3 className="text-header-3 mb-8">"The Forgotten Highlander" by Alistair Urquhart</h3>
            <h3 className="text-header-3 mb-8">"Awareness" by Anthony De Mello</h3>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Movies</h4>
            <h3 className="text-header-3 mb-8">The Indiana Jones Trilogy</h3>
            <h3 className="text-header-3 mb-8">Moonlight</h3>
            <h3 className="text-header-3 mb-8">Her</h3>
            <h3 className="text-header-3 mb-8">The Shawshank Redemption</h3>
            <h3 className="text-header-3 mb-8">Lord of the Rings</h3>
            <h3 className="text-header-3 mb-8">Legends of the Fall</h3>
            <h3 className="text-header-3 mb-8">Inception</h3>
            <h3 className="text-header-3 mb-8">Dumb and Dumber</h3>
            <h3 className="text-header-3 mb-8">In the Realm of the Senses</h3>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">TV Shows</h4>
            <h3 className="text-header-3 mb-8">Game of Thrones</h3>
            <h3 className="text-header-3 mb-8">Friends</h3>
            <h3 className="text-header-3 mb-8">The Office</h3>
            <h3 className="text-header-3 mb-8">Planet Earth</h3>
            <h3 className="text-header-3 mb-8">Top Gear</h3>
            <h3 className="text-header-3 mb-8">Who's Line is it Anyway?</h3>
            <h3 className="text-header-3 mb-8">South Park</h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">My favorite board games are...</h2>

            <h3 className="text-header-3 mb-8">Cards Against Humanity</h3>
            <h3 className="text-header-3 mb-8">Twilight Struggle</h3>
            <h3 className="text-header-3 mb-8">Wingspan</h3>
            <h3 className="text-header-3 mb-8">Azul</h3>
            <h3 className="text-header-3 mb-8">Castles of the Mad King Ludwig</h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">My favorite quotes are...</h2>

            <h3 className="text-header-3 mb-8">"Nature never hurries, yet all is accomplished."<span className="text-subheader-3">-Lao Tzu</span></h3>
            <h3 className="text-header-3 mb-8">"Most people overestimate what they can do in one year and underestimate what they can do in ten years."<span className="text-subheader-3">-Bill Gates</span></h3>
            <h3 className="text-header-3 mb-8">"If you're not busy being born, you're busy dying."<span className="text-subheader-3">-Bob Dylan</span></h3>
            <h3 className="text-header-3 mb-8">"I’ve had a lot of worries in my life, most of which never happened."<span className="text-subheader-3">-Mark Twain</span></h3>
            <h3 className="text-header-3 mb-8">"A genius is the man who can do the average thing when everyone else around him is losing his mind."<span className="text-subheader-3">-Napoleon Bonaparte </span></h3>
            <h3 className="text-header-3 mb-8">"No worthy problem is ever solved in the plane of its original conception."<span className="text-subheader-3">-Einstein</span></h3>
            <h3 className="text-header-3 mb-8">"The main thing is keeping the main thing the main thing."<span className="text-subheader-3">-Stephen Covey</span></h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12 mt-24">My favorite locations are...</h2>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Landscapes</h4>
            <h3 className="text-header-3 mb-8">Lake Dorothy in The Indian Peaks Wilderness</h3>
            <h3 className="text-header-3 mb-8">Mt. Fitz Roy in Patagonia</h3>
            <h3 className="text-header-3 mb-8">Harris Saddle in New Zealand</h3>
            <h3 className="text-header-3 mb-8">Salkantay Pass in Peru</h3>
            <h3 className="text-header-3 mb-8">Testa Bernarda in Italy</h3>
            <h3 className="text-header-3 mb-8">Calanais Standing Stones in The Outer Hebrides</h3>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Cities</h4>
            <h3 className="text-header-3 mb-8">Boulder, Colorado</h3>
            <h3 className="text-header-3 mb-8">Ljubljana, Slovenia</h3>
            <h3 className="text-header-3 mb-8">Telluride, Colorado</h3>
            <h3 className="text-header-3 mb-8">The Upper West Side, NYC</h3>
            <h3 className="text-header-3 mb-8">Marlborough, New Zealand</h3>
            <h3 className="text-header-3 mb-8">London, England</h3>

            <h4 className="text-subheader-1 text-mint mb-6 mt-16">Restaurants</h4>
            <h3 className="text-header-3 mb-8">Dishoom in London, England</h3>
            <h3 className="text-header-3 mb-8">Pizzeria du Tunnel in Courmayeur, Italy</h3>
            <h3 className="text-header-3 mb-8">Alinea in Chicago, Illinois</h3>
            <h3 className="text-header-3 mb-8">Eleven Madison Park in NYC, New York</h3>
            <h3 className="text-header-3 mb-8">Rincon Argentino in Boulder, Colorado</h3>
            <h3 className="text-header-3 mb-8">La Choza in Santa Fe, New Mexico</h3>
            <h3 className="text-header-3 mb-8">Inver in Cairndow, Scotland</h3>
            <h3 className="text-header-3 mb-8">Hiša Franko in Kobaird, Slovenia</h3>
            <h3 className="text-header-3 mb-8">Mama’s Fish House in Maui, Hawaii</h3>
            <h3 className="text-header-3 mb-8">Friðheimar in Reykholt, Iceland</h3>
            <h3 className="text-header-3 mb-8">Wairau River in Rapaura, New Zealand</h3>
            <h3 className="text-header-3 mb-8">Raw Pasta in Ljubljana, Slovenia</h3>
            <h3 className="text-header-3 mb-8">The Angel on the Bridge (Sunday Roast) in Henley, England</h3>
          </div>

          <div className="layout-reading-w text-center my-32">
            <h2 className="text-body-2 italic mb-12">My greatest fears are...</h2>

            <h3 className="text-header-3 mb-8">Needles</h3>
            <h3 className="text-header-3 mb-8">Dancing</h3>
            <h3 className="text-header-3 mb-8">Public speaking</h3>
            <h3 className="text-header-3 mb-8">Living a life I'll come to regret</h3>
          </div>

        </div>
      </div>
    </BaseLayout>
  )

}

export default AboutPage

const query = graphql`
  query About {
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
  }
`
